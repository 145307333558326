import * as React from 'react';

import {
  Button,
  fetchEnd,
  fetchStart,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

import PublishIcon from '@material-ui/icons/Publish';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const MyImportButton = (props) => {
  const hiddenFileInput = React.useRef(null);
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  
  const handleChange = (event) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('items', fileUploaded);
    fetch(`/api/warehouse/inject${props.type === 'xlsx' ? '-excel' : ''}`, { method: 'PUT', body: formData })
      .then((response) => response.json())
      .then((data) => {
        if (!data?.success) {
          throw new Error(data?.message || 'Ошибка');
        } else {
          notify(
            `Успешно обновлено ${data?.updatedDocumentsCount} из ${data?.totalCount} товаров`
          );
          redirect('/items');
          refresh();
        }
      })
      .catch((e) => {
        notify(e?.message || 'Ошибка запроса', 'warning');
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <>
      <input
        type="file"
        onChange={handleChange}
        // onBlur={() => alert('test')}
        className="hidden-input"
        ref={hiddenFileInput}
      />
      <Button label={`Импорт ${props.type}`} onClick={handleClick} disabled={loading}>
        <PublishIcon />
      </Button>
    </>
  );
};

export default MyImportButton;
