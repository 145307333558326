import * as React from 'react';

import { Link, useQueryWithStore, useTranslate } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// import { useForm } from 'react-final-form';

const useStyles = makeStyles({
  rightAlignedCell: { textAlign: 'right' },
});

const Basket = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  // const form = useForm();
  // const formdata = form.getState().values;

  const { loaded, data: products } = useQueryWithStore(
    {
      type: 'getMany',
      resource: `${record.prefix || ''}items`,
      payload: {
        ids: record ? record.items.map((item) => item.id) : [],
      },
    },
    {},
    (state) => {
      const productIds = record ? record.items.map((item) => item.id) : [];

      return productIds
        .map(
          (productId) =>
            state.admin.resources[`${record.prefix}items`]?.data[productId]
        )
        .filter((r) => typeof r !== 'undefined')
        .reduce((prev, next) => {
          prev[next.id] = next;
          return prev;
        }, {});
    }
  );

  if (!loaded || !record) return null;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.manufacturer')}
          </TableCell>
          <TableCell>
            {translate('resources.commands.fields.items.reference')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {translate('resources.commands.fields.items.unit_price_usd')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {translate('resources.commands.fields.items.unit_price')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {translate('resources.commands.fields.items.rate')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {translate('resources.commands.fields.items.quantity')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {translate('resources.commands.fields.items.summ')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.items.map(
          (item) =>
            products[item.id] && (
              <TableRow key={item.id}>
                <TableCell>{item.manufacturer}</TableCell>
                <TableCell>
                  <Link to={`/${record.prefix}items/${item.id}`}>
                    {products[item.id].name}
                  </Link>
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {/* {products[item.id].price.toLocaleString(undefined, { */}
                  {item.price.toLocaleString(undefined, {
                    style: 'currency',
                    currency:
                      item.currency === '$'
                        ? 'USD'
                        : item.currency === '€'
                        ? 'EUR'
                        : 'UAH',
                  })}
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {/* {products[item.id].price.toLocaleString(undefined, { */}
                  {(item.price * item.rate).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'UAH',
                  })}
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {item.rate}
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {item.quantity}
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {/* {(products[item.id].price * item.quantity).toLocaleString( */}
                  {(item.price * item.quantity * item.rate).toLocaleString(
                    undefined,
                    {
                      style: 'currency',
                      currency: 'UAH',
                    }
                  )}
                </TableCell>
              </TableRow>
            )
        )}
      </TableBody>
    </Table>
  );
};

export default Basket;
