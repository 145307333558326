import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

import { InputAdornment } from '@material-ui/core';
import React from 'react';

export const DiscountsList = (props) => {
  console.log('props', props);
  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={props.options.label}
      filter={{ only_custom: true }}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Наименование" />
        <TextField source="discount" label="Скидка %" />
      </Datagrid>
    </List>
  );
};
// export const DiscountsList = (props) => (
//   <List bulkActionButtons={false} {...props} title={props.options.label}>
//     <Datagrid rowClick="edit">
//       <TextField source="name" label="Наименование" />
//       <TextField source="discount" label="Скидка %" />
//     </Datagrid>
//   </List>
// );

const DiscountsTitle = ({ record }) => {
  return <span>Скидка для {record ? `"${record.name}"` : ''}</span>;
};

const DiscountsEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const DiscountsEdit = (props) => (
  <Edit title={<DiscountsTitle />} {...props}>
    <SimpleForm toolbar={<DiscountsEditToolbar />}>
      <TextInput label="Наименование" source="name" validate={[required()]} />
      <NumberInput
        label="Скидка"
        source="discount"
        step={0.1}
        max={100}
        format={(v) => parseFloat(v)}
        parse={(v) => v.replace(/,/g, '.').toString()}
        initialValue={0}
        validate={[required()]}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
    </SimpleForm>
  </Edit>
);

export const DiscountsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Наименование" source="name" validate={[required()]} />
      <NumberInput
        label="Скидка"
        source="discount"
        step={0.1}
        max={100}
        format={(v) => parseFloat(v)}
        parse={(v) => v.replace(/,/g, '.').toString()}
        initialValue={0}
        validate={[required()]}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
    </SimpleForm>
  </Create>
);
