import {
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FormDataConsumer,
  Labeled,
  List,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  maxLength,
  minLength,
  required,
} from 'react-admin';

import MobileGrid from './MobileGrid';
import PhoneField from './PhoneField';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';

const validatePassword = [minLength(6)];

const CustomerFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const CustomerList = (props) => {
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <List
      {...props}
      title={props.options.label}
      sort={{ field: 'signUpDate', order: 'DESC' }}
      filters={<CustomerFilter />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" label="Имя" />
          <TextField source="email" label="Email" />
          <TextField source="companyName" label="Название компании" />
          <PhoneField source="phone" label="Телефон" />
          <ReferenceField
            label="Категория цен"
            source="role"
            reference="discounts"
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="signUpDate" label="Дата регистрации" />
        </Datagrid>
      )}
    </List>
  );
};

const CustomerTitle = ({ record }) => {
  return <span>Клиент {record ? `"${record.name}"` : ''}</span>;
};

// const SelectInputDefaultValue = ({ optionText, ...rest }) => {
//   const { choices } = rest;
//   let defaultChoice = choices && choices.length === 1 ? choices[0] : '';
//   return (
//     <SelectInput
//       optionText={optionText}
//       defaultValue={defaultChoice.id}
//       {...rest}
//     />
//   );
// };

export const CustomerEdit = (props) => (
  <Edit title={<CustomerTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Имя" source="name" validate={[required()]} />
      <TextInput label="Email" source="email" />
      <TextInput label="Название компании" source="companyName" />
      <ReferenceInput
        label="Категория цен"
        source="role"
        reference="discounts"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        label="Номер телефона"
        source="phone"
        validate={[required(), maxLength(10), minLength(10)]}
        inputProps={{
          maxLength: 10,
        }}
      />
      <BooleanInput label="Изменить пароль" source="chPass" />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) =>
          formData.chPass && (
            <Labeled
              label={`Введите новый пароль, затем отправьте его клиенту вручную. Пароль будет доступен для просмотра только 1 раз`}
            >
              <PasswordInput
                label="Введите новый пароль"
                source="password"
                initiallyVisible
                disabled={!formData.chPass}
                validate={validatePassword}
              />
            </Labeled>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
