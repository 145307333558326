import russianMessages from 'ra-language-russian';

const customRussianMessages = {
  ...russianMessages,
  pos: {
    configuration: 'Настройки',
    phone: 'Номер телефона',
    language: 'Язык',
    configure_users: 'Управление',
    users_link: 'Пользователи',
    theme: {
      name: 'Тема',
      light: 'Светлая',
      dark: 'Темная',
    },
    dashboard: {
      exchange_rate: 'Курс валют',
      items_count: 'Кол-во товаров',
      new_users: 'Новые пользователи',
    },
    menu: {
      users: 'Пользователи',
      customers: 'Клиенты',
      rates: 'Курс Валют',
      discounts: 'Категории цен',
      brands: 'Бренды',
      manufacturers: 'Производители',
      categories: 'Категории',
      items: 'Товары',
      photos: 'Фото',
      errorcodes: 'Коды ошибок',
      articles: 'Статьи',
      conditioners: 'Прайс кондиционеры',
      ventilations: 'Прайс вент. обор.',
      heaters: 'Прайс тепловые насосы',
      tool: 'Прайс инстр. и дрен. насосы',
      consumables: 'Прайс расх. мат. и химия',
      dryer: 'Прайс осушители',
      notifications: 'Push уведомления',
      orders: 'Заказы',
      contacts: 'Контакты',
      settings: 'Настройки',
    },
  },
  resources: {
    items: {
      tabs: {
        main: 'Основное',
        details: 'Детали',
        photo: 'Фото',
      },
    },
    categories: {
      fields: {
        items: 'Товары',
      },
    },
    customers: {
      name: 'Клиент',
      fields: {
        name: 'Клиент',
        phone_number: 'Номер телефона',
        discount: 'Категория цен',
      },
    },
    rates: {
      name: 'Валюты',
      fields: {
        name: 'Валюта',
        cashRate: 'Нал',
        cashlessRate: 'Безнал',
        date: 'Дата изменения',
      },
    },
    error_codes: {
      name: 'Коды ошибок',
      fields: {
        name: 'Код ошибки',
        description: 'Описание',
      },
    },
    notifications: {
      name: 'Push Уведомления',
      fields: {
        name: 'Уведомление',
        date: 'Дата',
      },
    },
    contacts: {
      name: 'Контакты',
      fields: {
        contact: {
          label: 'Наименование',
          name: 'Имя',
          phone: 'Номер телефона',
          email: 'Email',
        },
        office: {
          label: 'Наименование',
          name: 'Адрес',
          lat: 'Широта',
          lng: 'Долгота',
        },
        list: {
          contact1: 'Контакт 1',
          contact2: 'Контакт 2',
          contact3: 'Контакт 3',
          office: 'Адрес 1',
          office2: 'Адрес 2',
        },
        phoneNumber: 'Контактный номер (в приложении)',
      },
    },
    commands: {
      name: 'Заказы',
      title: 'Заказы',
      section: {
        order: 'Заказ',
        customer: 'Клиент',
        shipping_address: 'Адрес доставки',
        items: 'Товары',
        totals: 'Итоги',
        total: 'Итог',
      },
      fields: {
        nb_items: 'К-во',
        items: {
          manufacturer: 'Производитель',
          reference: 'Наименование',
          unit_price_usd: 'Цена',
          unit_price: 'Цена, грн',
          rate: 'Курс',
          quantity: 'Кол-во',
          summ: 'Сумма, грн',
          total: 'Всего',
          sum: 'Сумма',
          delivery: 'Доставка',
          taxes: 'Налог',
        },
        status: 'Статус',
        returned: 'Возврат',
        date: 'Дата',
        ordered: 'Заказан',
        delivered: 'Доставлен',
        processed: 'Обработан',
        cancelled: 'Отменен',
      },
    },
  },
  ra: {
    ...russianMessages.ra,
    navigation: {
      ...russianMessages.ra.navigation,
      skip_nav: 'Перейти к содержанию',
    },
  },
};

export default customRussianMessages;
