import Configuration from './configuration/Configuration';
import DeleteAccountPage from './pages/DeleteAccount';
import React from 'react';
import { Route } from 'react-router-dom';
import { RouteWithoutLayout } from 'react-admin';

export default [
  <Route
    key="configuration"
    exact
    path="/configuration"
    component={Configuration}
  />,
  <RouteWithoutLayout
    key="delete-account"
    exact
    path="/delete-account"
    component={DeleteAccountPage}
  />,
];
