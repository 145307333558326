import './App.css';

import * as React from 'react';

import { Admin, Resource } from 'react-admin';
import {
  ArticlesCreate,
  ArticlesEdit,
  ArticlesList,
} from './resources/articles';
// import { BrandCreate, BrandEdit, BrandList } from './resources/brands';
import {
  CategoriesCreate,
  CategoriesEdit,
  CategoriesList,
} from './resources/catalog-cond/categories';
import {
  ConsumablesCategoriesCreate,
  ConsumablesCategoriesEdit,
  ConsumablesCategoriesList,
} from './resources/catalog-consumables/consumablesCategories';
import {
  ConsumablesItemsCreate,
  ConsumablesItemsEdit,
  ConsumablesItemsList,
} from './resources/catalog-consumables/consumablesItems';
import {
  ContactsCreate,
  ContactsEdit,
  ContactsList,
} from './resources/contacts';
import { CustomerEdit, CustomerList } from './resources/customers';
import {
  DiscountsCreate,
  DiscountsEdit,
  DiscountsList,
} from './resources/discounts';
import {
  DryerCategoriesCreate,
  DryerCategoriesEdit,
  DryerCategoriesList,
} from './resources/catalog-dryer/dryerCategories';
import {
  DryerItemsCreate,
  DryerItemsEdit,
  DryerItemsList,
} from './resources/catalog-dryer/dryerItems';
import {
  ErrorCodesCreate,
  ErrorCodesEdit,
  ErrorCodesList,
} from './resources/errorCodes';
import {
  HeaterCategoriesCreate,
  HeaterCategoriesEdit,
  HeaterCategoriesList,
} from './resources/catalog-heater/heaterCategories';
import {
  HeaterItemsCreate,
  HeaterItemsEdit,
  HeaterItemsList,
} from './resources/catalog-heater/heaterItems';
import {
  ItemsCreate,
  ItemsEdit,
  ItemsList,
} from './resources/catalog-cond/items';
import { Layout, Login } from './layout';
import {
  ManufacturersCreate,
  ManufacturersEdit,
  ManufacturersList,
} from './resources/catalog-cond/manufacturers';
import {
  NotificationsCreate,
  NotificationsEdit,
  NotificationsList,
} from './resources/notifications';
import { PhotosCreate, PhotosEdit, PhotosList } from './resources/photos';
import { RateCreate, RateEdit, RateList } from './resources/rates';
import {
  SettingsCreate,
  SettingsEdit,
  SettingsList,
} from './resources/settings';
import {
  ToolCategoriesCreate,
  ToolCategoriesEdit,
  ToolCategoriesList,
} from './resources/catalog-tool/toolCategories';
import {
  ToolItemsCreate,
  ToolItemsEdit,
  ToolItemsList,
} from './resources/catalog-tool/toolItems';
import { UserEdit, UserList } from './resources/users';
import {
  VentCategoriesCreate,
  VentCategoriesEdit,
  VentCategoriesList,
} from './resources/catalog-vent/ventCategories';
import {
  VentItemsCreate,
  VentItemsEdit,
  VentItemsList,
} from './resources/catalog-vent/ventItems';

import ArticlesIcon from '@material-ui/icons/Description';
// import BrandIcon from '@material-ui/icons/Apple';
import CategoriesIcon from '@material-ui/icons/FormatListBulleted';
import ContactsIcon from '@material-ui/icons/Contacts';
import CustomerIcon from '@material-ui/icons/Group';
import { Dashboard } from './dashboard/index';
import DiscountsIcon from '@material-ui/icons/Loyalty';
import ErrorCodesIcon from '@material-ui/icons/Error';
import ItemsIcon from '@material-ui/icons/AssignmentTurnedIn';
import ManufacturersIcon from '@material-ui/icons/Build';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PhotosIcon from '@material-ui/icons/Photo';
import RateIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import authProvider from './authProvider';
import customRoutes from './routes';
import jsonServerProvider from './dataProvider';
import orders from './resources/orders/index';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from './i18n/ru';
import themeReducer from './themeReducer';

const dataProvider = jsonServerProvider('/api');

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'en') {
    return import('./i18n/en').then((messages) => messages.default);
  }
  if (locale === 'ua') {
    return import('./i18n/ua').then((messages) => messages.default);
  }

  return russianMessages;
}, 'ru');

const App = () => {
  return (
    <Admin
      title="Панель управления"
      dataProvider={dataProvider}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
    >
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        icon={CustomerIcon}
        options={{ label: 'pos.menu.users' }}
      />
      <Resource
        name="customers"
        list={CustomerList}
        edit={CustomerEdit}
        icon={CustomerIcon}
        options={{ label: 'pos.menu.customers' }}
      />
      <Resource
        name="rates"
        list={RateList}
        edit={RateEdit}
        create={RateCreate}
        icon={RateIcon}
        options={{ label: 'pos.menu.rates' }}
      />
      <Resource
        name="discounts"
        list={DiscountsList}
        edit={DiscountsEdit}
        create={DiscountsCreate}
        icon={DiscountsIcon}
        options={{ label: 'pos.menu.discounts' }}
      />
      <Resource
        name="contacts"
        list={ContactsList}
        edit={ContactsEdit}
        create={ContactsCreate}
        icon={ContactsIcon}
        options={{ label: 'pos.menu.contacts' }}
      />
      <Resource
        name="orders"
        {...orders}
        options={{ label: 'pos.menu.orders' }}
      />
      {/* <Resource
        name="brands"
        list={BrandList}
        edit={BrandEdit}
        create={BrandCreate}
        icon={BrandIcon}
        options={{ label: 'pos.menu.brands' }}
      /> */}
      <Resource
        name="manufacturers"
        list={ManufacturersList}
        edit={ManufacturersEdit}
        create={ManufacturersCreate}
        icon={ManufacturersIcon}
        options={{ label: 'pos.menu.manufacturers' }}
      />
      <Resource
        name="categories"
        list={CategoriesList}
        edit={CategoriesEdit}
        create={CategoriesCreate}
        icon={CategoriesIcon}
        options={{ label: 'pos.menu.categories' }}
      />
      <Resource
        name="items"
        list={ItemsList}
        edit={ItemsEdit}
        create={ItemsCreate}
        icon={ItemsIcon}
        options={{ label: 'pos.menu.items' }}
      />
      <Resource
        name="photos"
        list={PhotosList}
        edit={PhotosEdit}
        create={PhotosCreate}
        icon={PhotosIcon}
        options={{ label: 'pos.menu.photos' }}
      />
      <Resource
        name="errorcodes"
        list={ErrorCodesList}
        edit={ErrorCodesEdit}
        create={ErrorCodesCreate}
        icon={ErrorCodesIcon}
        options={{ label: 'pos.menu.errorcodes' }}
      />
      <Resource
        name="settings"
        list={SettingsList}
        edit={SettingsEdit}
        create={SettingsCreate}
        icon={SettingsIcon}
        options={{ label: 'pos.menu.settings' }}
      />
      <Resource
        name="articles"
        list={ArticlesList}
        edit={ArticlesEdit}
        create={ArticlesCreate}
        icon={ArticlesIcon}
        options={{ label: 'pos.menu.articles' }}
      />
      <Resource
        name="vent-manufacturers"
        list={ManufacturersList}
        edit={ManufacturersEdit}
        create={ManufacturersCreate}
        icon={ManufacturersIcon}
        options={{ label: 'pos.menu.manufacturers' }}
      />
      <Resource
        name="vent-categories"
        list={VentCategoriesList}
        edit={VentCategoriesEdit}
        create={VentCategoriesCreate}
        icon={CategoriesIcon}
        options={{ label: 'pos.menu.categories' }}
      />
      <Resource
        name="vent-items"
        list={VentItemsList}
        edit={VentItemsEdit}
        create={VentItemsCreate}
        icon={ItemsIcon}
        options={{ label: 'pos.menu.items' }}
      />
      <Resource
        name="heater-manufacturers"
        list={ManufacturersList}
        edit={ManufacturersEdit}
        create={ManufacturersCreate}
        icon={ManufacturersIcon}
        options={{ label: 'pos.menu.manufacturers' }}
      />
      <Resource
        name="heater-categories"
        list={HeaterCategoriesList}
        edit={HeaterCategoriesEdit}
        create={HeaterCategoriesCreate}
        icon={CategoriesIcon}
        options={{ label: 'pos.menu.categories' }}
      />
      <Resource
        name="heater-items"
        list={HeaterItemsList}
        edit={HeaterItemsEdit}
        create={HeaterItemsCreate}
        icon={ItemsIcon}
        options={{ label: 'pos.menu.items' }}
      />
      <Resource
        name="tool-manufacturers"
        list={ManufacturersList}
        edit={ManufacturersEdit}
        create={ManufacturersCreate}
        icon={ManufacturersIcon}
        options={{ label: 'pos.menu.manufacturers' }}
      />
      <Resource
        name="tool-categories"
        list={ToolCategoriesList}
        edit={ToolCategoriesEdit}
        create={ToolCategoriesCreate}
        icon={CategoriesIcon}
        options={{ label: 'pos.menu.categories' }}
      />
      <Resource
        name="tool-items"
        list={ToolItemsList}
        edit={ToolItemsEdit}
        create={ToolItemsCreate}
        icon={ItemsIcon}
        options={{ label: 'pos.menu.items' }}
      />
      <Resource
        name="consumables-manufacturers"
        list={ManufacturersList}
        edit={ManufacturersEdit}
        create={ManufacturersCreate}
        icon={ManufacturersIcon}
        options={{ label: 'pos.menu.manufacturers' }}
      />
      <Resource
        name="consumables-categories"
        list={ConsumablesCategoriesList}
        edit={ConsumablesCategoriesEdit}
        create={ConsumablesCategoriesCreate}
        icon={CategoriesIcon}
        options={{ label: 'pos.menu.categories' }}
      />
      <Resource
        name="consumables-items"
        list={ConsumablesItemsList}
        edit={ConsumablesItemsEdit}
        create={ConsumablesItemsCreate}
        icon={ItemsIcon}
        options={{ label: 'pos.menu.items' }}
      />
      <Resource
        name="dryer-manufacturers"
        list={ManufacturersList}
        edit={ManufacturersEdit}
        create={ManufacturersCreate}
        icon={ManufacturersIcon}
        options={{ label: 'pos.menu.manufacturers' }}
      />
      <Resource
        name="dryer-categories"
        list={DryerCategoriesList}
        edit={DryerCategoriesEdit}
        create={DryerCategoriesCreate}
        icon={CategoriesIcon}
        options={{ label: 'pos.menu.categories' }}
      />
      <Resource
        name="dryer-items"
        list={DryerItemsList}
        edit={DryerItemsEdit}
        create={DryerItemsCreate}
        icon={ItemsIcon}
        options={{ label: 'pos.menu.items' }}
      />
      <Resource
        name="notifications"
        list={NotificationsList}
        edit={NotificationsEdit}
        create={NotificationsCreate}
        icon={NotificationsIcon}
        options={{ label: 'pos.menu.notifications', disabled: false }}
      />
    </Admin>
  );
};

export default App;
