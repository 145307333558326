import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React from 'react';
import { stringify } from 'query-string';
import { useTranslate } from 'react-admin';

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="dryer-manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const LinkToRelatedProducts = ({ record }) => {
  const translate = useTranslate();
  return (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: '/dryer-items',
        search: stringify({
          page: 1,
          perPage: 25,
          filter: JSON.stringify({ category_q: record.id }),
        }),
      }}
    >
      {translate('resources.categories.fields.items')}
    </Button>
  );
};

export const DryerCategoriesList = (props) => (
  <List
    filters={<CategoriesFilter />}
    {...props}
    title={props.options.label}
    sort={{ field: 'order', order: 'ASC' }}
  >
    <Datagrid>
      <TextField source="name" label="Название категории" />
      <ReferenceField
        label="Производитель"
        source="manufacturer"
        reference="dryer-manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="order" label="Порядок" />
      <LinkToRelatedProducts />
      <EditButton />
    </Datagrid>
  </List>
);

const CategoriesTitle = ({ record }) => {
  return <span>Категория {record ? `"${record.name}"` : ''}</span>;
};

export const DryerCategoriesEdit = (props) => (
  <Edit title={<CategoriesTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Производитель"
        source="manufacturer"
        reference="dryer-manufacturers"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="name"
        label="Название категории"
        validate={[required()]}
      />
      <TextInput source="description" label="Описание" multiline fullWidth />
      <NumberInput
        source="order"
        label="Порядок"
        min={1}
        max={99}
        step={1}
        validate={[required()]}
      />
      <BooleanInput source="isHidden" label="Не показывать" />
    </SimpleForm>
  </Edit>
);

export const DryerCategoriesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Производитель"
        source="manufacturer"
        reference="dryer-manufacturers"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="name"
        label="Название категории"
        validate={[required()]}
      />
      <TextInput source="description" label="Описание" multiline fullWidth />
      <NumberInput
        source="order"
        label="Порядок"
        min={1}
        max={99}
        step={1}
        validate={[required()]}
      />
      <BooleanInput source="isHidden" label="Не показывать" />
    </SimpleForm>
  </Create>
);
