import { Box, useMediaQuery } from '@material-ui/core';
import {
  DashboardMenuItem,
  MenuItemLink,
  getResources,
  useTranslate,
} from 'react-admin';
import React, { useState } from 'react';

import AcUnitIcon from '@material-ui/icons/AcUnit';
import DefaultIcon from '@material-ui/icons/ViewList';
import EcoRoundedIcon from '@material-ui/icons/EcoRounded';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import PermDataSettingRoundedIcon from '@material-ui/icons/PermDataSettingRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import SubMenu from './SubMenu';
import ToysIcon from '@material-ui/icons/Toys';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { useSelector } from 'react-redux';

const Menu = ({ onMenuClick, logout, dense = false }) => {
  const [state, setState] = useState({
    menuConditioners: false,
    menuVentilations: false,
    menuHeaters: false,
    menuTool: false,
    menuConsumables: false,
    menuDryer: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);
  const resources = useSelector(getResources);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box mt={1}>
      {' '}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {resources
        .filter(
          (val) =>
            val.name !== 'users' &&
            val.name !== 'manufacturers' &&
            val.name !== 'categories' &&
            val.name !== 'items' &&
            val.name !== 'vent-manufacturers' &&
            val.name !== 'vent-categories' &&
            val.name !== 'vent-items' &&
            val.name !== 'heater-manufacturers' &&
            val.name !== 'heater-categories' &&
            val.name !== 'heater-items' &&
            val.name !== 'tool-manufacturers' &&
            val.name !== 'tool-categories' &&
            val.name !== 'tool-items' &&
            val.name !== 'consumables-manufacturers' &&
            val.name !== 'consumables-categories' &&
            val.name !== 'consumables-items' &&
            val.name !== 'dryer-manufacturers' &&
            val.name !== 'dryer-categories' &&
            val.name !== 'dryer-items'
        )
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && translate(resource.options.label)) ||
              resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            disabled={resource.options.disabled}
          />
        ))}
      <SubMenu
        handleToggle={() => handleToggle('menuConditioners')}
        isOpen={state.menuConditioners}
        sidebarIsOpen={open}
        name="pos.menu.conditioners"
        icon={<AcUnitIcon />}
        dense={dense}
      >
        {resources
          .filter(
            (val) =>
              val.name === 'manufacturers' ||
              val.name === 'categories' ||
              val.name === 'items'
          )
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && translate(resource.options.label)) ||
                resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          ))}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuVentilations')}
        isOpen={state.menuVentilations}
        sidebarIsOpen={open}
        name="pos.menu.ventilations"
        icon={<ToysIcon />}
        dense={dense}
      >
        {resources
          .filter(
            (val) =>
              val.name === 'vent-manufacturers' ||
              val.name === 'vent-categories' ||
              val.name === 'vent-items'
          )
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && translate(resource.options.label)) ||
                resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          ))}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuHeaters')}
        isOpen={state.menuHeaters}
        sidebarIsOpen={open}
        name="pos.menu.heaters"
        icon={<WhatshotIcon />}
        dense={dense}
      >
        {resources
          .filter(
            (val) =>
              val.name === 'heater-manufacturers' ||
              val.name === 'heater-categories' ||
              val.name === 'heater-items'
          )
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && translate(resource.options.label)) ||
                resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          ))}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuTool')}
        isOpen={state.menuTool}
        sidebarIsOpen={open}
        name="pos.menu.tool"
        icon={<PermDataSettingRoundedIcon />}
        dense={dense}
      >
        {resources
          .filter(
            (val) =>
              val.name === 'tool-manufacturers' ||
              val.name === 'tool-categories' ||
              val.name === 'tool-items'
          )
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && translate(resource.options.label)) ||
                resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          ))}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuConsumables')}
        isOpen={state.menuConsumables}
        sidebarIsOpen={open}
        name="pos.menu.consumables"
        icon={<EcoRoundedIcon />}
        dense={dense}
      >
        {resources
          .filter(
            (val) =>
              val.name === 'consumables-manufacturers' ||
              val.name === 'consumables-categories' ||
              val.name === 'consumables-items'
          )
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && translate(resource.options.label)) ||
                resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          ))}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuDryer')}
        isOpen={state.menuDryer}
        sidebarIsOpen={open}
        name="pos.menu.dryer"
        icon={<FormatColorResetIcon />}
        dense={dense}
      >
        {resources
          .filter(
            (val) =>
              val.name === 'dryer-manufacturers' ||
              val.name === 'dryer-categories' ||
              val.name === 'dryer-items'
          )
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && translate(resource.options.label)) ||
                resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          ))}
      </SubMenu>
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate('pos.configuration')}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </Box>
  );
};

export default Menu;
