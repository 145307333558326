import * as React from 'react';

import {
  Create,
  Datagrid,
  Edit,
  List,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  required,
  useTranslate,
} from 'react-admin';

import { Divider } from '@material-ui/core';

export const ContactsList = (props) => {
  const translate = useTranslate();

  return (
    <List
      bulkActionButtons={false}
      {...props}
      title={translate('pos.menu.contacts')}
      filter={{ only_custom: true }}
    >
      <Datagrid rowClick="edit">
        <TextField
          source="contact1.name"
          label={translate('resources.contacts.fields.list.contact1')}
        />
        <TextField
          source="contact2.name"
          label={translate('resources.contacts.fields.list.contact2')}
        />
        <TextField
          source="contact3.name"
          label={translate('resources.contacts.fields.list.contact3')}
        />
        <TextField
          source="office.name"
          label={translate('resources.contacts.fields.list.office')}
        />
        <TextField
          source="office2.name"
          label={translate('resources.contacts.fields.list.office2')}
        />
      </Datagrid>
    </List>
  );
};

const Title = () => {
  return <span>Контакты</span>;
};

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const ContactsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <p>
          <b>Контакт 1</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact1.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact1.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact1.phone"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact1.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 2</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact2.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact2.name"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact2.phone"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact2.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 3</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact3.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact3.name"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact3.phone"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact3.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 4</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact4.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact4.name"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact4.phone"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact4.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 5</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact5.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact5.name"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact5.phone"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact5.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 6</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact6.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact6.name"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact6.phone"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact6.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Адрес 1</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.office.label')}
          source="office.label"
          validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.office.name')}
          source="office.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lat')}
          source="office.lat"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lng')}
          source="office.lng"
          validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Адрес 2</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.office.label')}
          source="office2.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.office.name')}
          source="office2.name"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lat')}
          source="office2.lat"
          // validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lng')}
          source="office2.lng"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <TextInput
          label={translate('resources.contacts.fields.phoneNumber')}
          source="phoneNumber"
          validate={[required()]}
          // fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export const ContactsCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props}>
      <SimpleForm>
        <p>
          <b>Контакт 1</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact1.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact1.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact1.phone"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact1.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 2</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact2.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact2.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact2.phone"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact2.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 3</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact3.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact3.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact3.phone"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact3.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 4</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact4.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact4.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact4.phone"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact4.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 5</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact5.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact5.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact5.phone"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact5.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Контакт 6</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.contact.label')}
          source="contact6.label"
          // validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.name')}
          source="contact6.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.phone')}
          source="contact6.phone"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.contact.email')}
          source="contact6.email"
          // validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Адрес 1</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.office.label')}
          source="office.label"
          validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.office.name')}
          source="office.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lat')}
          source="office.lat"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lng')}
          source="office.lng"
          validate={[required()]}
        />
        <Divider fullWidth />
        <p>
          <b>Адрес 2</b>
        </p>
        <TextInput
          label={translate('resources.contacts.fields.office.label')}
          source="office2.label"
          validate={[required()]}
          // fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.office.name')}
          source="office2.name"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lat')}
          source="office2.lat"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lng')}
          source="office2.lng"
          validate={[required()]}
        />
        <Divider fullWidth />
        <TextInput
          label={translate('resources.contacts.fields.phoneNumber')}
          source="phoneNumber"
          validate={[required()]}
          // fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
