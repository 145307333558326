import * as React from 'react';

import {
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  ExportButton,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  downloadCSV,
  required,
} from 'react-admin';

import { CreateButton } from 'ra-ui-materialui';
import Divider from '@material-ui/core/Divider';
import { InputAdornment } from '@material-ui/core';
import MyImportButton from './MyImportButton';
import { cloneElement } from 'react';
import jsonExport from 'jsonexport/dist';

const ItemsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    {props.filterValues.manufacturer_q && (
      <ReferenceInput
        label="Категория"
        source="category_q"
        reference="categories"
        perPage={200}
        filter={{ manufacturer_q: props.filterValues.manufacturer_q }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}
    <SelectInput
      source="type_q"
      label="Тип"
      allowEmpty={false}
      choices={[
        { id: 'common', name: 'Бытовые кондиционеры' },
        { id: 'multi', name: 'Мульти-сплит системы' },
        { id: 'commercial', name: 'Коммерческие кондиционеры' },
      ]}
    />
  </Filter>
);

const exporter = (records, fetchRelatedRecords) => {
  // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
  fetchRelatedRecords(records, 'itemKey', 'items').then((posts) => {
    const data = records.map((record) => ({
      name: record.name,
      stock: record.stock1 || '0',
      optPrice: record.optPrice || '0',
      price: record.price || '0',
    }));
    jsonExport(
      data,
      {
        headers: ['name', 'stock', 'optPrice', 'price'],
      },
      (err, csv) => {
        downloadCSV(csv, 'items');
      }
    );
  });
};

const ListActions = (props) => {
  const {
    className,
    resource,
    basePath,
    total,
    showFilter,
    filters,
    displayedFilters,
    filterValues,
  } = props;
  return (
    <TopToolbar className={className}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        {...props}
        maxResults={2000}
        className={'export-button'}
        exporter={exporter}
        disabled={total === 0}
      />
      <MyImportButton {...props} type="csv" />
      <MyImportButton {...props} type="xlsx" />
    </TopToolbar>
  );
};

export const ItemsList = (props) => (
  <List
    {...props}
    filters={<ItemsFilter />}
    actions={<ListActions />}
    title={props.options.label}
    sort={{ field: 'order', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Название товара" />
      <ReferenceField
        label="Категория"
        source="category"
        reference="categories"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField
        source="typeCat"
        label="Тип"
        choices={[
          { id: 'common', name: 'Бытовые кондиционеры' },
          { id: 'multi', name: 'Мульти-сплит системы' },
          { id: 'commercial', name: 'Коммерческие кондиционеры' },
        ]}
      />
      <TextField source="optPrice" label="Цена, опт." />
      <TextField source="price" label="Цена, розн." />
      <TextField source="stock1" label="Остаток" />
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

const ItemsTitle = ({ record }) => {
  return <span>Товар {record ? `"${record.name}"` : ''}</span>;
};

export const ItemsEdit = (props) => (
  <Edit title={<ItemsTitle />} {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <ReferenceInput
          label="Производитель"
          source="manufacturer"
          reference="manufacturers"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="typeCat"
          label="Тип"
          choices={[
            { id: 'common', name: 'Бытовые кондиционеры' },
            { id: 'multi', name: 'Мульти-сплит системы' },
            { id: 'commercial', name: 'Коммерческие кондиционеры' },
          ]}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <ReferenceInput
                label="Категория"
                source="category"
                reference="categories"
                perPage={100}
                filter={{ manufacturer_q: formData.manufacturer }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput source="itemKey" label="Артикул" />
        <TextInput
          source="name"
          label="Название товара"
          validate={[required()]}
        />
        <NumberInput
          source="optPrice"
          label="Цена, опт."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">₴</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          validate={[required()]}
        />
        <BooleanInput source="isHidden" label="Не показывать" />
        <Divider />
        <p>Склад</p>
        <NumberInput
          source="stock1"
          label="Наличие"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput fullWidth source="power" label="Мощность охл. / обогр." />
        <TextInput
          fullWidth
          source="powerСonsumption"
          label="Потребляемая мощность охл. / обогр. (кВт)"
        />
        <TextInput fullWidth source="temp" label="Диапазон внешн. температур" />
        <TextInput fullWidth source="mark" label="Марка компрессора" />
        <TextInput
          fullWidth
          source="dimensions"
          label="Габариты вн. / нар. блок"
        />
        <TextInput
          fullWidth
          source="connectionWires"
          label="Подключение проводов"
        />
        <TextInput
          fullWidth
          source="connectionPipelines"
          label="Подключение трубопроводов"
        />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <>
                <ReferenceInput
                  label="Серия"
                  source="photo"
                  reference="photos"
                  filter={{ manufacturer_q: formData.manufacturer }}
                  perPage={200}
                  {...rest}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                {/* <ReferenceField label="Фото" source="photo" reference="photos">
                  <ImageField source="image" label="Текущее Фото" />
                </ReferenceField> */}
              </>
            ) : null
          }
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ItemsCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <ReferenceInput
          label="Производитель"
          source="manufacturer"
          reference="manufacturers"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="typeCat"
          label="Тип"
          choices={[
            { id: 'common', name: 'Бытовые кондиционеры' },
            { id: 'multi', name: 'Мульти-сплит системы' },
            { id: 'commercial', name: 'Коммерческие кондиционеры' },
          ]}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <ReferenceInput
                label="Категория"
                source="category"
                reference="categories"
                perPage={100}
                filter={{ manufacturer_q: formData.manufacturer }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput source="itemKey" label="Артикул" />
        <TextInput
          source="name"
          label="Название товара"
          validate={[required()]}
        />
        <NumberInput
          source="optPrice"
          label="Цена, опт."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">₴</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          initialValue={99}
          validate={[required()]}
        />
        <BooleanInput source="isHidden" label="Не показывать" />
        <Divider />
        <p>Склад</p>
        <NumberInput
          source="stock1"
          label="Наличие"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput fullWidth source="power" label="Мощность охл. / обогр." />
        <TextInput
          fullWidth
          source="powerСonsumption"
          label="Потребляемая мощность охл. / обогр. (кВт)"
        />
        <TextInput fullWidth source="temp" label="Диапазон внешн. температур" />
        <TextInput fullWidth source="mark" label="Марка компрессора" />
        <TextInput
          fullWidth
          source="dimensions"
          label="Габариты вн. / нар. блок"
        />
        <TextInput
          fullWidth
          source="connectionWires"
          label="Подключение проводов"
        />
        <TextInput
          fullWidth
          source="connectionPipelines"
          label="Подключение трубопроводов"
        />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <>
                <ReferenceInput
                  label="Серия"
                  source="photo"
                  reference="photos"
                  filter={{ manufacturer_q: formData.manufacturer }}
                  perPage={200}
                  {...rest}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                {/* <ReferenceField label="Фото" source="photo" reference="photos">
                  <ImageField source="image" label="Текущее Фото" />
                </ReferenceField> */}
              </>
            ) : null
          }
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Create>
);
