import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import React from 'react';

const SettingsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const SettingsList = (props) => (
  <List filters={<SettingsFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Наименование" />
      <BooleanField source="maintenance" label="Технические работы" />
      <BooleanField
        source="useAutoUpdateItems"
        label="Авто обновление товаров"
      />
    </Datagrid>
  </List>
);

const SettingsTitle = ({ record }) => {
  return <span>Настройки {record ? `"${record.name}"` : ''}</span>;
};

export const SettingsEdit = (props) => (
  <Edit title={<SettingsTitle />} {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Наименование"
        validate={[required()]}
        disabled
      />
      <TextInput
        source="uniqueId"
        label="ID"
        validate={[required()]}
        disabled
      />
      <BooleanInput source="maintenance" label="Технические работы" />
      <BooleanInput
        source="useAutoUpdateItems"
        label="Авто обновление товаров"
        fullWidth
      />
    </SimpleForm>
  </Edit>
);

export const SettingsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Наименование"
        validate={[required()]}
      />
      <TextInput
        source="uniqueId"
        label="ID"
        validate={[required()]}
      />
      <BooleanInput source="maintenance" label="Технические работы" />
      <BooleanInput
        source="useAutoUpdateItems"
        label="Авто обновление товаров"
        fullWidth
      />
    </SimpleForm>
  </Create>
);
