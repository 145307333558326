import { AuthProvider } from 'react-admin';

const authProvider: AuthProvider = {
  login: ({ phone, password }) => {
    const request = new Request(`/api/admin-account/signin`, {
      method: 'POST',
      body: JSON.stringify({ phone, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          // @ts-ignore
          throw new Error(response.message);
        }
        return response.json();
      })
      .then(({ token, role }) => {
        if (role === 'admin') {
          localStorage.setItem('token', token);
          localStorage.setItem('permissions', role);
        } else {
          throw new Error('У вас нет прав для данной операции');
        }
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role === 'admin' || role === 'manager'
      ? Promise.resolve(role)
      : Promise.reject();
  },
};

export default authProvider;
