import { useQueryWithStore, useTranslate } from 'react-admin';

import AssignmentTurnedIIcon from '@material-ui/icons/AssignmentTurnedIn';
import Card from '@material-ui/core/Card';
import CardIcon from './CardIcon';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {
    flex: '1',
    marginLeft: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  title: {
    // lineHeight: '14px',
  },
  titleLink: { textDecoration: 'none', color: 'inherit' },
});

const locations = [
  {
    pathname: 'items',
    query: {},
  },
  {
    pathname: 'vent-items',
    query: {},
  },
  {
    pathname: 'heater-items',
    query: {},
  },
  {
    pathname: 'tool-items',
    query: {},
  },
  {
    pathname: 'consumables-items',
    query: {},
  },
];

const Items = () => {
  const translate = useTranslate();
  const classes = useStyles();

  const { loaded: loadedCond, total: totalCond } = useQueryWithStore({
    type: 'getList',
    resource: 'items',
    payload: {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    },
  });

  const { loaded: loadedVent, total: totalVent } = useQueryWithStore({
    type: 'getList',
    resource: 'vent-items',
    payload: {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    },
  });

  const { loaded: loadedHeater, total: totalHeater } = useQueryWithStore({
    type: 'getList',
    resource: 'heater-items',
    payload: {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    },
  });

  const { loaded: loadedTool, total: totalTool } = useQueryWithStore({
    type: 'getList',
    resource: 'tool-items',
    payload: {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    },
  });

  const {
    loaded: loadedConsumables,
    total: totalConsumables,
  } = useQueryWithStore({
    type: 'getList',
    resource: 'consumables-items',
    payload: {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    },
  });

  if (
    !loadedCond ||
    !loadedVent ||
    !loadedHeater ||
    !loadedTool ||
    !loadedConsumables
  )
    return null;

  return (
    <div className={classes.main}>
      <CardIcon Icon={AssignmentTurnedIIcon} bgColor="#ff9800" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate('pos.dashboard.items_count')}
        </Typography>
        <Divider />
        <List>
          <ListItem
            button
            to={locations[0]}
            className={classes.titleLink}
            component={Link}
            key={1}
          >
            <ListItemText primary={'Кондиционеры:'} />
            <ListItemSecondaryAction>
              <span className={classes.cost}>{totalCond}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button
            to={locations[1]}
            className={classes.titleLink}
            component={Link}
            key={2}
          >
            <ListItemText primary={'Вент. обор.:'} />
            <ListItemSecondaryAction>
              <span className={classes.cost}>{totalVent}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button
            to={locations[2]}
            className={classes.titleLink}
            component={Link}
            key={3}
          >
            <ListItemText primary={'Бойлеры:'} />
            <ListItemSecondaryAction>
              <span className={classes.cost}>{totalHeater}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button
            to={locations[3]}
            className={classes.titleLink}
            component={Link}
            key={4}
          >
            <ListItemText primary={'Инстр. и дрен. насосы:'} />
            <ListItemSecondaryAction>
              <span className={classes.cost}>{totalTool}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button
            to={locations[4]}
            className={classes.titleLink}
            component={Link}
            key={5}
          >
            <ListItemText primary={'Расх. мат. и химия:'} />
            <ListItemSecondaryAction>
              <span className={classes.cost}>{totalConsumables}</span>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Card>
    </div>
  );
};

export default Items;
